import { SafeSessionStorage } from '@utils/browser';

import Cookies from 'js-cookie';

const LEAD_TOKEN_KEY = '__clutter:agent_lead_token';

export const TEAL_USER_ID = Cookies.get('teal_user_id');

export const AGENT_BOOKING_LEAD_TOKEN = (() => {
  if (typeof window === 'undefined') return undefined;

  const token =
    SafeSessionStorage.getItem(LEAD_TOKEN_KEY) ??
    new URLSearchParams(window.location.search).get('lead_token') ??
    undefined;

  if (token) {
    SafeSessionStorage.setItem(LEAD_TOKEN_KEY, token);
  }

  return token;
})();
