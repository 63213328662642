import 'core-js/stable';

import 'regenerator-runtime/runtime';

import ReactRailsUJS from 'react_ujs';

import { NotFound } from '@root/components/errors/not_found';

import { hydrate } from '@utils/hydrate';

import '../initializers/new_relic';
import '../initializers/sentry';
import '../styles/errors';

ReactRailsUJS.getConstructor = (name: string) => {
  switch (name) {
    case '404':
      return NotFound;
  }
};

hydrate();
