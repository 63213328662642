import React from 'react';

import { startCase } from 'lodash-es';

import {
  AbeetExecuteVariables,
  AbeetProvider,
  AbeetResultResolver,
  AbeetTestOptions,
  useAbeetTest,
} from '@clutter/abeet';

import { API_BASE_URL } from '@root/config/constants';
import { WT_VISITOR_TOKEN, WT_VISITOR_TOKEN_KEY } from '@root/initializers/wt';

import * as FullStory from '@fullstory/browser';

export const LandingAbeetProvider: React.FC<
  Omit<React.ComponentProps<typeof AbeetProvider>, 'baseUrl'>
> = (props) => (
  <AbeetProvider
    {...props}
    onResult={(familyName, variables) => {
      if (FullStory.isInitialized() && variables !== null) {
        FullStory.setUserVars({
          [`abeet${startCase(familyName).replace(/ /g, '')}_str`]:
            JSON.stringify(variables),
        });
      }
    }}
    baseUrl={API_BASE_URL!}
    options={{ headers: { 'Clutter-Client': 'landing_frontend' } }}
  />
);

/**
 * @param propertyName A variable from a configured Abeet test
 * @param substring The value which indicates eligibility if it matches with the tests variant
 */
export const variantSubstringResolver =
  (propertyName: string, substring: string) =>
  (result: AbeetExecuteVariables) =>
    Boolean(result && result[propertyName]?.includes(substring));

export function useAbeetVisitorTest<T>(
  familyName: string,
  options: {
    skip?: boolean;
    resolver: AbeetResultResolver<T>;
  },
): [result: T, loading: boolean];
export function useAbeetVisitorTest(
  familyName: string,
  options?: {
    skip?: boolean;
  },
): [result: AbeetExecuteVariables, loading: boolean];
export function useAbeetVisitorTest<T>(
  familyName: string,
  options: AbeetTestOptions<T> = {
    skip: false,
  },
) {
  return useAbeetTest(
    WT_VISITOR_TOKEN_KEY,
    WT_VISITOR_TOKEN,
    familyName,
    options,
  );
}
