/* eslint-disable landing/no-direct-wt-usage */
import wt, { WTEventParams, createProvider } from '@clutter/wt';

import { TEAL_USER_ID } from '@root/components/checkout/helpers/agent_booking';
import { EventWithSchema } from '@root/resources/wt/types';

import { getPageName } from '@utils/get_pagename_utilities';
import { isomorphicConfig } from '@utils/isomorphic_config';
import { reportError } from '@utils/monitoring';

import { WT_COOKIE_EXPIRES } from '../config/constants';
import Cookies from 'js-cookie';

if (typeof window !== 'undefined') {
  const {
    wt: { cookieDomain, trackerDomain },
  } = isomorphicConfig();
  wt.initialize({
    trackerDomain,
    cookieOptions: {
      domain: cookieDomain,
      expires: WT_COOKIE_EXPIRES,
    },
    onError(payload, error) {
      reportError(error as Error, {
        tags: { library: 'wt' },
        metadata: { visitorToken: WT_VISITOR_TOKEN },
      });
    },
  });
}

const WT_PROXY_TOKEN = Cookies.get('wt_proxy_token');

if (WT_PROXY_TOKEN) {
  wt.config({ visitorToken: WT_PROXY_TOKEN });
}

export const WT_VISITOR_TOKEN_KEY = 'wt_visitor_token';
export const WT_VISITOR_TOKEN = wt.getVisitorToken();
export const WT_PAGE_UUID = wt.getUUIDToken();

if (TEAL_USER_ID) {
  wt.set({ metadata: { user_id: TEAL_USER_ID } });
}

const extractPageName = (params: WTEventParams) =>
  'pageName' in params ? params.pageName : getPageName();

export const track = (params: WTEventParams | EventWithSchema | string) => {
  if (typeof params === 'string') {
    wt.track(params);
  } else {
    const pageName = extractPageName(params);
    wt.track({ pageName, ...params });
  }
};

let lastPathname = '';

/**
 * Pageviews should only be tracked via this method. This guards against
 * accidentally tracking duplicate pageviews (e.g. once when a pack loads, and
 * then again when the application mounts if the <Pageview /> component is used)
 */
export const trackPageview = (params: WTEventParams = {}) => {
  if (
    typeof window !== 'undefined' &&
    window.location.pathname !== lastPathname
  ) {
    const pageName = extractPageName(params);
    track({ ...params, pageName, kind: 'pageview' });
    lastPathname = window.location.pathname;
  }
};

export const flushEvents = () => wt.flush();

const {
  WTProvider,
  useTrack: innerUseTrack,
  useWT,
} = createProvider<WTEventParams | EventWithSchema>(track);

export const useTrack = (
  params?: Partial<WTEventParams> | EventWithSchema,
): ((params: Partial<WTEventParams> | EventWithSchema) => void) =>
  innerUseTrack(params);

export { WTProvider, useWT };
