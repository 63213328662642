import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS, Text } from '@clutter/clean';

import { useTrack } from '@root/initializers/wt';

import { CloseButton } from '@shared/close_button';
import { ContactPhoneLink } from '@shared/contact_phone_link';

import logoDark from '../../../images/www/logo_dark_teal.svg';

export interface IMenuItem {
  label: string;
  href: string;
}

const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
  overflow-y: hidden;
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 96px;
  padding: 6px 16px 12px;
  align-items: center;
`;

const XButton = styled(CloseButton)`
  height: 24px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const LinkText = styled(Text.Title)`
  color: ${COLORS.tealDark};
  margin: 24px;
`;

const StyledATag = styled.a`
  color: ${COLORS.tealDark};
`;

const CallContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

const CallLink = styled.div`
  border-radius: 4px;
  border: solid 1px ${COLORS.tealDark};
  padding: 16px 32px;

  & > a {
    font-weight: bold;
    font-size: 16px;
    color: ${COLORS.tealDark};
    text-decoration: none;
  }
`;

const Menu: React.FC<{
  menuItems: IMenuItem[];
  onCloseClick: () => void;
}> = ({ onCloseClick, menuItems }) => {
  const track = useTrack();
  React.useEffect(() => {
    const scollPositionX = window.scrollX;
    const scrollPositionY = window.scrollY;
    document.body.style.position = 'fixed';
    return () => {
      document.body.style.position = '';
      window.scrollTo(scollPositionX, scrollPositionY);
    };
  }, []);

  return (
    <FullScreenContainer>
      <Nav>
        <img src={logoDark} width="128" height="30.8" alt="Clutter" />
        <XButton
          color={COLORS.tealDark}
          onClick={onCloseClick}
          className="CloseButton"
        />
      </Nav>
      <Links>
        {menuItems.map((item, index) => {
          return (
            <LinkText key={index} size="medium">
              <StyledATag href={item.href}>{item.label}</StyledATag>
            </LinkText>
          );
        })}
      </Links>
      <CallContainer>
        <CallLink>
          <ContactPhoneLink
            onClick={(e) =>
              track({
                label: e.currentTarget.innerText,
                value: e.currentTarget.href,
                container: 'header_mobile',
                objectType: 'link',
                objectName: 'call_us_button',
                action: 'click',
              })
            }
          >
            {(num) => <span>Call Now: {'1 ' + num}</span>}
          </ContactPhoneLink>
        </CallLink>
      </CallContainer>
    </FullScreenContainer>
  );
};

export { Menu };
