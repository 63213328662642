import * as React from 'react';

import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';

import { COLORS } from '@clutter/clean';

import { WTProvider, useTrack } from '@root/initializers/wt';

import { LandingAbeetProvider } from '@shared/abeet';
import { Header } from '@shared/content/header';

import { Layout } from '@utils/clean';
import { useOnMount } from '@utils/hooks/mount';

const styles = css`
  html,
  body {
    width: 100%;
    height: 100%;
    background: white;
    text-align: center;
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }
`;

const Content = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const Background = styled.div`
  font-size: 50vh;
  color: ${COLORS.grayBackground};
  position: absolute;
  top: 10vh;
  text-align: center;
  z-index: 0;
  width: 100%;
  font-family: Recoleta;
`;

const Headline = styled.h1`
  color: ${COLORS.tealDark};
  font-size: 3em;
  font-family: Recoleta;
  margin-top: 20vh;
  margin-bottom: 24px;
`;

const Message = styled.h3`
  font-size: 1.2em;
  width: 50%;
  min-width: 300px;
  text-align: center;
  margin: 0 auto;
  color: ${COLORS.storm};

  a {
    color: ${COLORS.tealBrand};
    text-decoration: underline;
    cursor: pointer;
  }
`;

const goBack = () => typeof window !== 'undefined' && history.back();

const NotFoundMessage: React.FC = () => {
  const track = useTrack();

  useOnMount(() => {
    track({
      action: 'display',
      objectName: 'not_found_message',
    });
  });

  return (
    <Layout>
      <Global styles={styles} />
      <Background>?</Background>
      <Content>
        <Header themeName="teal" />
        <Headline>Something’s not quite right!</Headline>
        <Message>
          We’re not sure what you’re looking for, but it’s not here. <br />
          Try <a onClick={goBack}>going back</a> or{' '}
          <a href="https://www.clutter.com">visit our home page</a>.
        </Message>
      </Content>
    </Layout>
  );
};

const NotFound: React.FC = () => {
  return (
    <WTProvider params={{ pageName: 'not_found' }}>
      <LandingAbeetProvider>
        <NotFoundMessage />
      </LandingAbeetProvider>
    </WTProvider>
  );
};

export { NotFound };
